import { useState, useEffect } from 'react';
import * as Survey from "survey-react-ui";
import { FunctionFactory } from "survey-core";
import { PlainLight } from "survey-core/themes/plain-light"
import 'survey-core/defaultV2.min.css';
import "survey-core/i18n/french";
import Header from './header';
import Privacy from './privacy';

function FetchRequest(body, response) {
  fetch("/serviceQuestionnaire.php", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((Response) => Response.json()).then(response);
}

function Questionnaire({ code, signOnly=false, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [valid, setValid] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [currentPageNo, setCurrentPageNo] = useState(null);
  const [privacyUnsigned, setPrivacyUnsigned] = useState(false);
  const [privacyOnly, setPrivacyOnly] = useState(false);
  const [noMed, setNoMed] = useState(false);
  
  let json = require('./survey.json');
  
  useEffect(() => {
    FetchRequest({action: 'get_state', code: code}, (resp) => {
      setLoaded(true);
      setValid(resp.valid);
      setPatientInfo(resp.patient);
      setSurveyData(resp.data);
      setCurrentPageNo(resp.currentpage);
      setPrivacyUnsigned(resp.privacyUnsigned);
      setPrivacyOnly(resp.privacyOnly);
    });
  }, [code]);
  
  const onPageChange = (survey, options) => {
    FetchRequest({action: 'post_state', code: code, data: survey.data, currentpage: survey.currentPageNo}, (resp) => { });
  }
  
  const onComplete = (survey, options) => {
    //Write survey results into database
    options.showDataSaving();
    FetchRequest({action: 'post_submit', code: code, data: survey.data}, (resp) => {
      if (resp.success) {
        options.showDataSavingSuccess();
      } else {
        options.showDataSavingError();
      }
    });
  }
  
  const getContent = () => {
    if (!loaded) return (<div className="content center"><br />Chargement du questionnaire...</div>);
    else if (!valid) return (<div className="content center"><br />Lien invalide ou questionnaire déjà rempli.</div>);
    else if (privacyUnsigned) return (<Privacy code={code} privacyOnly={privacyOnly} signOnly={signOnly} onSigned={(f) => {setPrivacyUnsigned(false); setNoMed(f || signOnly) } } />)
    else if (noMed) return (<div className="content center"><h2>Questionnaire terminé</h2><p>Merci d'avoir rempli le questionnaire médical.</p></div>);
    else {
      FunctionFactory.Instance.register("patientSex", () => patientInfo.sexe);
      var model = new Survey.Model(json);
      model.applyTheme(PlainLight);
      model.localse = "fr";
      model.onCurrentPageChanged.add((s, o) => onPageChange(s, o));
      model.onComplete.add((s, o) => onComplete(s, o));
      return (<div class="survey-frame"><Survey.Survey model={model} data={surveyData} currentPageNo={currentPageNo} /></div>);
    }
  }
 
  return (
      <>
        <Header title={privacyUnsigned ? "Politique de confidentialité" : null} infoText={loaded && valid ? 'Patient'+ (patientInfo.sexe === 'F' ? 'e' : '')+': '+patientInfo.prenom+' '+patientInfo.nf : null} />
        {getContent()}
      </>
    );
}

export default Questionnaire;
